<template>
    <div class="appmain">
        <div class="title">{{$t('search.searchResult')}}</div>
        <div class="searchbtn">
             <el-input placeholder="" v-model="input2" @keyup.enter.native="searchbtn">
                <el-button slot="append" style="background: #0052D9;color:#fff" @click="searchbtn">{{$t('search.inquire')}}</el-button>
            </el-input>
        </div>
        <div class="tishi">{{$t('search.search')}}"<span style="color:#0052D9">{{nowinput}}</span>"{{$t('search.dedao')}}<span style="color:#0052D9">{{tatal}}{{$t('search.tiao')}}</span></div>
        <div class="fenlei">
            <el-radio-group v-model="radio" >
                <el-radio :label="0">{{$t('search.all')}}</el-radio>
                <el-radio :label="1" v-if="products.length>0">{{$t('layout.productcenter')}}</el-radio>
                <el-radio :label="2" v-if="scheme.length>0">{{$t('layout.solution')}}</el-radio>
                <el-radio :label="3" v-if="project.length>0">{{$t('layout.application')}}</el-radio>
                <!-- <el-radio :label="4">技术支持</el-radio> -->
                <el-radio :label="5" v-if="hr.length>0">{{$t('layout.about')}}</el-radio>
                <el-radio :label="6" v-if="smartsale.length>0">{{$t('layout.about')}}</el-radio>
            </el-radio-group>
        </div>
        <div class="datitle" v-if="products.length>0 && radio == 0 || products.length>0 && radio == 1">{{$t('layout.productcenter')}}</div>
        <div class="chanpin" v-if="products.length>0 && radio == 0 || products.length>0 && radio == 1" >
            <el-row :gutter="30">
                <el-col :span="6" v-for="(item,index) in products" :key="index">
                    <div class="cpitem" @click="nav('/product/'+item.product_id)">
                        <div class="chanpinimage" >
                            <el-image style="width: 180px;max-height: 150px;" :src="item.get_image_list[0].product_img"></el-image>
                        </div>
                        <div class="chanpintitle">
                            {{item.product_name}}
                        </div>
                        <div class="chanpinfubiao" :title="item.product_feature">
                            {{item.product_feature}}
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="datitle" v-if="scheme.length>0 && radio == 0 || scheme.length>0 && radio == 2">{{$t('layout.solution')}}</div>
        <div class="fangan" v-if="scheme.length>0 && radio == 0 || scheme.length>0 && radio == 2">
            <el-row :gutter="20">
                <el-col :span="8" :xs="24" v-for="(item,index) in scheme" :key="index">
                    <div style="background:#fff;padding-bottom:20px;margin-bottom:20px;" @click="nav('/scheme/'+item.scheme_id)">
                        <el-image class="jjimage" :src="item.scheme_image"></el-image>
                        <div class="jjtitle">{{item.scheme_title}}</div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="datitle" v-if="project.length>0 && radio == 0 || project.length>0 && radio == 3">{{$t('layout.application')}}</div>
        <div class="anli" v-if="project.length>0 && radio == 0 || project.length>0 && radio == 3">
            <el-row>
                <el-col :span="22" v-for="(item,index) in project" :key="index">
                    <div @click="nav('/projectinfo/'+item.project_id)">
                        <div class="yytitl">{{item.project_title}}</div>
                        <div class="yytitles">{{item.project_desc_text}}</div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <!-- <div class="datitle">技术支持</div>
        <div class="anli">
            <el-row>
                <el-col :span="22" v-for="(item,index) in 3" :key="index">
                    <div class="yytitl">HGM612ON V1.0中文说明书</div>
                    <div class="yytitles">中文说明书内容中文说明书内容中文说明书内容中文说明书内容中文说明书内容中文说明书内容中文说明书内容中文说明书内容中文说明书内容中文说明书内容中文说明书内容中文说明书内容中文…</div>
                </el-col>
            </el-row>
        </div> -->
        <div class="datitle" v-if="hr.length>0 && radio == 0 || hr.length>0 && radio == 5">{{$t('layout.about')}}</div>
        <div class="anli" v-if="hr.length>0 && radio == 0 || hr.length>0 && radio == 5">
            <el-row>
                <el-col :span="22" v-for="(item,index) in hr" :key="index">
                    <div class="yytitl">{{$t('search.socialRecruitment')}} | {{item.hr_title}}</div>
                    <div class="yytitles">{{item.hr_desc_text}}</div>
                </el-col>
            </el-row>
        </div>
        <div class="datitle" v-if="smartsale.length>0  && radio == 0 || smartsale.length>0 && radio == 6">{{$t('layout.contact')}}</div>
        <div class="anli" v-if="smartsale.length>0  && radio == 0 || smartsale.length>0 && radio == 6">
            <el-row>
                <el-col :span="22" v-for="(item,index) in smartsale" :key="index">
                    <div class="yytitl">{{item.class_name}}</div>
                    <div class="yytitles">{{item.name}} {{$t('search.mobilePhone')}}：{{item.phone}} {{$t('search.mailbox')}}：{{item.email}}</div>
                    <div class="yytitles">负责区域:{{item.zone}}</div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import { getSearchList} from "@/api/api"; 
export default {
    data(){
        return{
            radio:0,
            input2:'',
            downlist:[],
            hr:[],
            products:[],
            project:[],
            scheme:[],
            smartsale:[],
            tatal:0,
            nowinput:'',
            title:''
        }
    },
    created(){
        this.title = this.$t('layout.search')+'-'+this.$t('seo.indextitle')
        let key = this.$route.query.key
        this.nowinput = key
        this.input2 = key
        this.getList()
    },
    metaInfo () {
        return {
        title: this.title,
        meta: [
            {
                name: 'keyWords',
                content: this.$t('seo.indexkeywords')
            },
            {
                name: 'description',
                content: this.$t('seo.indexdescription')
            }
        ]
        }
    },
    methods:{
        async getList(){
            if(this.input2 == ''){
                this.downlist = []
                this.hr = []
                this.products = []
                this.project = []
                this.scheme = []
                this.smartsale = []
                this.tatal = 0
                return
            }
            let data = {
                key:this.input2
            }
            const {data:res} = await getSearchList(data)
            res.products.forEach((item)=>{
                item.get_image_list.forEach((items)=>{
                    items.product_img = process.env.VUE_APP_BASE_API+items.product_img
                })
            })
            this.downlist = res.downlist
            this.hr = res.hr
            this.products = res.products
            this.project = res.project
            res.scheme.forEach((item)=>{
                item.scheme_image = process.env.VUE_APP_BASE_API+item.scheme_image
            })
            this.scheme = res.scheme
            this.smartsale = res.smartsale
            this.tatal = res.total
        },
        searchbtn(){
            this.$router.push('/search?key='+this.input2,'_blank')
        },
        nav(path){
            this.$router.push(path,'_blank')
        }
    }
}
</script>
<style lang="scss" scoped>
    .appmain{
        width: 70%;
        margin: 0 auto;
        margin-top: 2%;
    }
    @media screen and (max-width: 480px) {
        .appmain{
            width: 100%;
        }
    }
    .title{
        font-size: 40px;
        font-weight: bold;
        color: #333333;
        text-align: center;
    }
    .searchbtn{
        margin-top: 3%;
    }
    .tishi{
        font-size: 16px;
        color: #333333;
        margin-top: 1%;
    }
    .fenlei{
        margin-top: 1%;
    }
    .datitle{
        color: #333333;
        font-weight: bold;
        font-size: 24px;
        margin-top: 3%;
    }
    .chanpin{
        margin-top: 20px;
        cursor: pointer;
        .cpitem{
            background: #fff;
            margin-bottom: 10%;
            padding-bottom: 10%;
        }
        .chanpinimage{
            width: 180px;
            margin: 0 auto;
        }
        .chanpintitle{
            text-align: center;
            margin-top: 20px;
            height: 40px;
        }
        .chanpinfubiao{
            text-align: center;
          
            color: rgba(51, 51, 51, 0.6);
            font-size: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-left: 16px;
            padding-right: 16px;
            height: 16px;
            line-height: 16px;
        }
    }
    .fangan{
        cursor: pointer;
        margin-top: 2%;
        .jjimage{
            width: 100%;
        }
        .jjtitle{
            margin-top: 3%;
            color: #333333;
            font-weight: 550;
            text-align: center;
        }
    }
    .anli{
        cursor: pointer;
        .yytitl{
            margin-top: 2%;
            font-size: 20px;
            font-weight: 550;
            color: #333333;
        }
        .yytitles{
            margin-top: 10px;
            font-size: 16px;
            color: rgba(51, 51, 51, 0.5);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
</style>
<style scoped>
    /deep/ .el-radio{
        margin-right: 80px;
    }
</style>